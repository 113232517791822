import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { ComponentProp } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import {
  getComponentContextProps,
  getTextKeyFromI18Expansion
} from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import { ComponentMarkupLanguage } from '@aurora/shared-generated/types/graphql-schema-types';
import type { CachedComponent, GlobalCss } from '@aurora/shared-types/components';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { collapseWhitespace } from '@aurora/shared-utils/helpers/objects/StringHelper';
import { getLog } from '@aurora/shared-utils/log';
import brightcovePlayerLoader from '@brightcove/player-loader';
import type { DOMNode } from 'html-react-parser';
import HTMLReactParser from 'html-react-parser';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { SharedEndUserComponent } from '../../../features/sharedCoreComponentRegistry';
import sharedCoreComponentRegistry from '../../../features/sharedCoreComponentRegistry';
import { UPLOAD_STATUS } from '../../../helpers/editor/EditorVideoHelper';
import type {
  RenderCustomComponentQuery,
  RenderCustomComponentQueryVariables
} from '../../../types/graphql-types';
import {
  addConsentButtonClickAction,
  getVideoConsentBannerElement
} from '../../common/CookieBanner/CookieConsentBannerHelper';
import { getExternalVideoConsentCookie } from '../../common/CookieBanner/CookieHelper';
import EditContext from '../../context/EditContext/EditContext';
import useCustomComponentTranslation from '../../useCustomComponentTranslation';
import useTranslation from '../../useTranslation';
import renderCustomComponent from '../RenderCustomComponent.query.graphql';
import localStyles from './CustomComponentContent.module.pcss';

const log = getLog(module);

const ErrorAlert = dynamic(() => import('./ErrorAlert'));

interface DOMElement {
  name: string;
  attribs: Record<string, unknown>;
  children: DOMElement[];
  data: unknown;
}

const transform = function transform(
  node: DOMNode & DOMElement,
  globalCss: GlobalCss,
  formatMessage: FormatMessage
) {
  if (node.type === 'script') {
    const body = node.children;
    if (body.length > 0) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Script {...node.attribs} strategy={'afterInteractive'}>{`${body[0].data}`}</Script>;
    } else {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Script {...node.attribs} strategy={'afterInteractive'} />;
    }
  } else if (node.type === 'tag') {
    switch (node.name) {
      case 'li:component': {
        const widget = sharedCoreComponentRegistry.getWidgetDescriptor(
          node.attribs?.id as SharedEndUserComponent
        );
        const props = node.attribs.props ? JSON.parse(`${node.attribs.props}`) : {};
        if (widget) {
          log.debug('rendering shared component %s for custom component', node.attribs.id);
          const WrappedComponent = widget.component;
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} />;
        }
        log.debug('shared component with id: %s not found', node.attribs?.id);
        break;
      }
      case 'li:i18n': {
        const message = formatMessage(node.attribs.key);
        return <>{message}</>;
      }
      default: {
        if (node.attribs) {
          if (node.attribs['class']) {
            const collapsedClassNames = collapseWhitespace(`${node.attribs['class']}`);
            if (collapsedClassNames.includes(' ')) {
              node.attribs['class'] = collapsedClassNames
                .split(' ')
                .map(className => globalCss?.tokens[className] || className)
                .join(' ');
            } else {
              const globalClassName = globalCss?.tokens[collapsedClassNames];
              if (globalClassName) {
                node.attribs['class'] = globalClassName;
              }
            }
          }
          Object.entries(node.attribs).forEach(([key, value]) => {
            if ((value as string).startsWith('<li:i18n')) {
              const textKey = getTextKeyFromI18Expansion(value as string);
              if (textKey) {
                node.attribs[key] = formatMessage(textKey);
              }
            }
          });
          return node;
        }
      }
    }
  }
};

interface Props {
  /**
   * The component.
   */
  cachedComponent: CachedComponent;

  /**
   * Specifies any props to pass to the custom component.
   */
  customComponentProps?: Array<ComponentProp>;
}

function getTemplateContent(
  templateId: string,
  globalCss: GlobalCss,
  formatMessage: FormatMessage,
  markup: string
): React.ReactElement {
  return (
    <>
      {globalCss?.css && (
        <Head key={templateId}>
          <style data-testid="CustomComponentContentCss" type="text/css">
            {globalCss?.css}
          </style>
        </Head>
      )}
      {HTMLReactParser(markup, {
        replace: node => {
          return transform(node as DOMNode & DOMElement, globalCss, formatMessage);
        }
      })}
    </>
  );
}

const CustomComponentContent: React.FC<React.PropsWithChildren<Props>> = ({
  cachedComponent,
  customComponentProps
}) => {
  const { component, globalCss } = cachedComponent;
  const { id: customComponentId } = component;
  const language = component?.template.markupLanguage;
  const { pageTemplateContext } = useContext(AppContext);
  const cx = useClassNameMapper(localStyles);
  const widgetContentReference = useRef<HTMLDivElement>(null);
  const { formatMessage: textFormatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.CUSTOM_COMPONENT_WIDGET,
    true
  );
  const {
    publicConfig: { showExternalVideoCookieBanner }
  } = useContext(TenantContext);
  const externalVideoConsentProvided = getExternalVideoConsentCookie();
  const showBanner = showExternalVideoCookieBanner && !externalVideoConsentProvided;
  const [videoElementsMap, setVideoElementsMap] = useState<Map<string, HTMLElement>>(new Map());

  const queryResult = useQueryWithTracing<
    RenderCustomComponentQuery,
    RenderCustomComponentQueryVariables
  >(module, renderCustomComponent, {
    variables: {
      componentId: customComponentId,
      context: {
        page: pageTemplateContext,
        component: {
          props: getComponentContextProps(customComponentProps),
          entities: []
        }
      }
    }
  });

  const { showEditControls } = useContext(EditContext);
  const { loading, data, error: queryError } = queryResult;
  const i18n = useCustomComponentTranslation(customComponentId, component.template.texts);
  const { formatMessage } = i18n;
  const markup = data?.component?.render?.html;

  /**
   * Load the brightcove player to render video when widget's element reference is available
   */
  useEffect(() => {
    const widgetContentElement = widgetContentReference.current;
    if (widgetContentElement) {
      const videoElements: NodeListOf<HTMLElement> =
        widgetContentElement.querySelectorAll('.lia-video-container');
      videoElements.forEach(videoElement => {
        const iframe = videoElement.querySelector('iframe');
        if (iframe && showBanner) {
          setVideoElementsMap(
            previousState =>
              new Map([...previousState, [videoElement.dataset.videoId, videoElement]])
          );
          const videoLink = `<a href="${videoElement.dataset.videoRemoteVid}" 
            class="${cx('lia-external-url')}" 
            target="_blank" rel="noreferrer noopener">${textFormatMessage('urlText')}</a>`;
          const bannerElement = getVideoConsentBannerElement(
            cx,
            videoElement,
            iframe?.src,
            textFormatMessage('bannerTitle', { url: videoLink }),
            textFormatMessage('buttonTitle')
          );
          videoElement.replaceWith(bannerElement);
        } else {
          const referencedExternalId = videoElement.dataset.videoRemoteVid;
          const accountId = videoElement.dataset.account;
          const parentReferenceNode = videoElement?.querySelector(
            '.lia-video-display-wrapper'
          ) as HTMLElement;
          const referenceNode = parentReferenceNode?.querySelector('video-js');
          if (referenceNode !== null) {
            videoElement.dataset.liaVideoStatus = UPLOAD_STATUS.LIVE;
            if (referenceNode) {
              brightcovePlayerLoader({
                refNode: referenceNode,
                refNodeInsert: 'replace',
                accountId,
                playerId: 'default'
              })
                .then(success => {
                  const player = success.ref;
                  player.catalog.getVideo(referencedExternalId, (error, video) => {
                    if (error) {
                      player.error({ code: 'video-processing-error' });
                    } else {
                      player.catalog.load(video);
                    }
                  });
                  return true;
                })
                .catch(error => {
                  log.error(error, 'Brightcove Player Error');
                  throw new Error(error);
                });
            }
          }
        }
      });
    }
  });

  if (queryError) {
    log.error(
      `Unable to fetch component to render for component id ${customComponentId}: ${queryError.message}`
    );
    if (showEditControls) {
      return <ErrorAlert customComponentId={customComponentId} error={queryError} />;
    } else {
      return null;
    }
  }

  if (!loading && !data?.component) {
    log.error('Component with id: %s not found', customComponentId);
    return null;
  }

  if (loading || textLoading) {
    return null;
  }

  if (showBanner) {
    addConsentButtonClickAction(widgetContentReference, cx, videoElementsMap);
  }

  function getHtmlContent(): React.ReactElement {
    return (
      <div
        className={cx('lia-content-wrap')}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={markup ? { __html: markup } : null}
        ref={widgetContentReference}
      />
    );
  }

  const templateId = cachedComponent.component.template.id;
  return (
    <QueryHandler<RenderCustomComponentQuery, RenderCustomComponentQueryVariables>
      queryResult={queryResult}
    >
      {(): React.ReactNode => {
        if (queryError) {
          log.error('Unable to load custom component.', queryError);
          return null;
        }
        return language === ComponentMarkupLanguage.Html
          ? getHtmlContent()
          : getTemplateContent(templateId, globalCss, formatMessage, markup);
      }}
    </QueryHandler>
  );
};

export default CustomComponentContent;
